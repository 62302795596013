export const handleVideo = () => {
    const videoContainer = document.querySelector('.video-container');
    const video = document.querySelector('.video');
    const videoButton = document.querySelector('.video-play');
    const videoThumbnail = document.querySelector('.video-thumbnail');


    if(videoContainer && videoButton && videoThumbnail) {
        videoContainer.addEventListener('click', () => {
            // videoButton.classList.toggle('--hidden');
            // videoThumbnail.classList.toggle('--hidden');
            videoButton.style.display = 'none';
            videoThumbnail.style.display = 'none';
            video.play()
        });
    }
}