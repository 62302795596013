import Swiper from "swiper/bundle";
import 'swiper/css';
import 'swiper/css/navigation';

export function handleTabs() {
    const tabsPanel = document.querySelectorAll(".gallery .tabs-panel");
    const tabsAnchor = document.querySelectorAll('.gallery .tabs-list__anchor');
    if(!tabsPanel.length && !tabsAnchor.length) return;

    const hash = window.location.hash;
    const tabsInDom = []
    for (let i = 0; i < tabsPanel.length; i++){
        tabsInDom.push({
            label: tabsPanel[i].getAttribute("data-tab"),
            index: i
        })
    }
    const setInitialTabActived = (tabLabel) => {
        tabsInDom.forEach(tab => {
            if(tab.label.includes(tabLabel)){
                tabsAnchor[tab.index].parentElement.setAttribute('aria-selected', "true")
                tabsPanel[tab.index].setAttribute('aria-hidden', "false")
                tabsAnchor[tab.index].parentElement.classList.add("active");
                tabsPanel[tab.index].style.display = "flex";
            }  
        })
    }

    switch (true) {
        case hash.includes("todas") && tabsInDom.some(tab => tab.label.includes("todas")):
            setInitialTabActived("todas")
        break;
        case hash.includes("exterior") && tabsInDom.some(tab => tab.label.includes("exterior")):
            setInitialTabActived("exterior")
        break;
        case hash.includes("interior") && tabsInDom.some(tab => tab.label.includes("interior")):
            setInitialTabActived("interior")
        break
        default:
            // Setea los primeros valores
            tabsAnchor[0].parentElement.setAttribute('aria-selected', "true")
            tabsPanel[0].setAttribute('aria-hidden', "false")
            tabsAnchor[0].parentElement.classList.add("active");
            tabsPanel[0].style.display = "flex";
        break;
    }

    tabsAnchor.forEach((btn, index) => btn.addEventListener("click", () => itemCb(index)));

    function itemCb(index) {
        for (let i = 0; i < tabsPanel.length; i++) {
            tabsPanel[i].style.display = "none";
            tabsPanel[i].setAttribute("aria-hidden", "true");
            tabsPanel[index].style.display = "flex"
            tabsPanel[index].setAttribute("aria-hidden", "false");
        }
        for (let i = 0; i < tabsAnchor.length; i++) {
            tabsAnchor[i].parentElement.classList.remove("active");
            tabsAnchor[i].parentElement.setAttribute("aria-selected", "false");
            tabsAnchor[index].parentElement.classList.add("active");
            tabsAnchor[index].parentElement.setAttribute("aria-selected", "true");
        }
    }
}

export function handleGalleryFilter() {
    const btn = document.querySelector(".gallery-filters-mobile__button")
    const close = document.querySelector(".gallery-filters-mobile__modal_close")
    const modal = document.querySelector(".gallery-filters-mobile__modal")
    const tabsList = document.querySelector('.gallery-tabs-list_mobile');
    const selectedOption = document.querySelector('.gallery-selected-option');

    btn?.addEventListener("click", () => {
        modal.style.display = "flex";
    })

    close?.addEventListener("click", () => {
        modal.style.display = "none";
    })
    
    tabsList?.addEventListener('click', (e) => {
        e.preventDefault();
    
        const tabName = e.target.getAttribute('href').slice(1);
        const tabsPanels = document.querySelectorAll('.gallery .tabs-panel');

        modal.style.display = "none";
    
        tabsPanels?.forEach((tab) => {
            if (tab.getAttribute('data-tab') === tabName) {
                tab.style.display = 'block';
                selectedOption.innerHTML = tabName;
            } else {
                tab.style.display = 'none';
            }
        });
    });
}

export function gallerySwiper() {
    if (document.querySelector(".gallery-swiper")) {
        const todas = new Swiper(".gallery-swiper__sliders__slider", {
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            pagination: {
                el: ".container_pag_nav1 .swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        const exterior = new Swiper(".gallery-swiper-ext__sliders__slider", {
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            pagination: {
                el: ".container_pag_nav2 .swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });

        const interior = new Swiper(".gallery-swiper-int__sliders__slider", {
            spaceBetween: 30,
            centeredSlides: true,
            loop: true,
            pagination: {
                el: ".container_pag_nav3 .swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }
}