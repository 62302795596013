export function handleTabs3() {
    const tabListItemAll = document.querySelector('.blog #tabs-list__item-all');
    const blogHeader = document.querySelector('.blog .tabs-content .blog-header');
    let url = new URL(window.location.href);
    let category = url.searchParams.get("category");
    const selected = document.querySelector(`.blog #tabs-list__item-${category}`);
    const selectedOption = document.querySelector('.blog-selected-option');

    if(selected) {
        selected.classList.add('active');
    }

    if(category && blogHeader) {
        blogHeader.style.display = 'none';
    } else if (blogHeader) {
        blogHeader.style.display = 'flex';
        blogHeader.style.justifyContent = 'space-between';
        tabListItemAll.classList.add('active');
    }

    if(selectedOption && selected) {
        selectedOption.innerText = selected.innerText;
    } else if(selectedOption && tabListItemAll) {
        selectedOption.innerText = tabListItemAll.innerText;
    }
}

export function handleBlogFilter() {
    const btn = document.querySelector(".blog .blog-filters-mobile__button");
    const close = document.querySelector(".blog .blog-filters-mobile__modal_close");
    const modal = document.querySelector(".blog .blog-filters-mobile__modal");

    btn?.addEventListener("click", () => {
        modal.style.display = "flex";
    })

    close?.addEventListener("click", () => {
        modal.style.display = "none";
    })
}

export function handleSiblingsFilter() {
    const btn = document.querySelector(".siblings .blog-filters-mobile__button");
    const close = document.querySelector(".siblings .blog-filters-mobile__modal_close");
    const modal = document.querySelector(".siblings .blog-filters-mobile__modal");
    const tabsList = document.querySelector('#blog-tabs-list_mobile');
    // const selectedOption = document.querySelector('.blog-selected-option');
    const item = document.querySelectorAll('.blog-tabs-list_mobile .tabs-list__item');

    btn?.addEventListener("click", () => {
        modal.style.display = "flex";
    })

    close?.addEventListener("click", () => {
        modal.style.display = "none";
    })

    tabsList?.addEventListener('click', (e) => {
        e.preventDefault();

        const tabName = e.target.getAttribute('data-tab');
        const tabsPanels = document.querySelectorAll('.tabs-panel');

        modal.style.display = "none";

        tabsPanels?.forEach((tab) => {
            if (tab.getAttribute('data-tab') === tabName) {
                tab.style.display = 'flex';
                tab.setAttribute('aria-hidden', 'false');
            } else {
                tab.style.display = 'none';
                tab.setAttribute('aria-hidden', 'true');
            }
        });
        
        // selectedOption.innerHTML = e.target.innerText;
    });
}