export const seriesList = () => {
    const ulSerie = document.querySelector("#serieList");
    const ulVersion = document.querySelector("#versionList");
    const serieSelected = document.querySelector("#serieSelected");
    const versionSelected = document.querySelector("#versionSelected");
    const inputSerie = document.querySelector("#inputSerie");
    const inputVersion = document.querySelector("#inputVersion");
    const selectSerie = document.querySelector("#selectSerie");
    const selectVersion = document.querySelector("#selectVersion");

    if (ulSerie) {
        fetch("/api/vehicles/series/")
            .then((response) => response.json())
            .then((series) => {
                series.forEach((serie) => {
                    // Crear un li por cada serie
                    const liSerie = document.createElement("li");
                    liSerie.textContent = serie.name;
                    liSerie.className = "option";

                    liSerie.addEventListener("click", () => {
                        fetch(`/api/vehicles/series/?serie_id=${serie.id}`)
                            .then((response) => response.json())
                            .then((serie) => serie[0].versions)
                            .then((versions) => {
                                ulVersion.innerHTML = ""; // Limpiar versiones anteriores

                                versions.forEach((version) => {
                                    // Crear un li por cada versión y lo agrego al ul
                                    const liVersion =
                                        document.createElement("li");
                                    liVersion.textContent = version.name;
                                    liVersion.className = "option";
                                    ulVersion?.appendChild(liVersion);

                                    liVersion.addEventListener("click", () => {
                                        // Para mostrar la versión seleccionada en el select
                                        versionSelected.textContent =
                                            version.name;
                                        inputVersion.value = version.id;
                                        inputVersion.value
                                            ? selectVersion.classList.remove(
                                                  "--has-error"
                                              )
                                            : null;

                                        // Obtener el elemento se agregarán los elementos
                                        const parentElement =
                                            document.querySelector(
                                                "#cotizarForm"
                                            );

                                        // Crear el div para mostrar la versión seleccionada
                                        const versionDivCreated =
                                            document.querySelector(
                                                ".version_box"
                                            );
                                        if (!versionDivCreated) {
                                            var versionDiv =
                                                document.createElement("div");
                                            versionDiv.classList.add(
                                                "version_box"
                                            );
                                            versionDiv.id = "version_box";
                                        }

                                        // Crear p para mostrar la versión seleccionada
                                        const versionTextCreated =
                                            document.querySelector(
                                                ".version_box_version"
                                            );
                                        if (!versionTextCreated) {
                                            const versionText =
                                                document.createElement("p");
                                            versionText.classList.add(
                                                "version_box_version"
                                            );
                                            versionText.id =
                                                "version_box_version";
                                            versionText.textContent =
                                                version.name;
                                            versionDiv?.appendChild(
                                                versionText
                                            );
                                        } else if (versionTextCreated) {
                                            versionTextCreated.textContent =
                                                version.name;
                                        }

                                        // Crear la imagen de la versión seleccionada
                                        const versionImgCreated =
                                            document.querySelector(
                                                ".version_box_img"
                                            );
                                        if (!versionImgCreated) {
                                            const versionImg =
                                                document.createElement("img");
                                            versionImg.classList.add(
                                                "version_box_img"
                                            );
                                            versionImg.id = "version_box_img";
                                            versionImg.alt = version.name;
                                            versionImg.src = version.image.url;
                                            versionDiv?.appendChild(versionImg);
                                        } else if (versionImgCreated) {
                                            versionImgCreated.alt =
                                                version.name;
                                            versionImgCreated.src =
                                                version.image.url;
                                        }

                                        if (!versionDivCreated) {
                                            // Agregar el div al form
                                            parentElement?.appendChild(
                                                versionDiv
                                            );

                                            // Mover el div versión antes del botón
                                            const button =
                                                document.querySelector(
                                                    "#cotizarFormBtn"
                                                );
                                            parentElement?.insertBefore(
                                                versionDiv,
                                                button
                                            );
                                        }
                                    });
                                });
                            });

                        // Para mostrar la serie seleccionada en el select
                        serieSelected.textContent = serie.name;
                        versionSelected.textContent = "Select version";
                        inputVersion.value = "";
                        inputSerie.value = serie.id;
                        inputSerie.value
                            ? selectSerie.classList.remove("--has-error")
                            : null;
                    });

                    // Agregar el li al ul de series
                    ulSerie?.appendChild(liSerie);
                });
            })
            .catch((error) => console.error(error));
    }
};

export const quotationBtnHandler = () => {
    const form = document.querySelector('#cotizarForm');

    if (form) {
        const btn = form.querySelector('.btn-small');
        btn?.addEventListener('click', () => {
            const errorElements = form.querySelectorAll('.--has-error');
            if (errorElements.length == 0) {
                btn.disabled = true;
            }
        });
    }
}
