export const inquiryError = () => {
    const inputInquiry = document.querySelector('#inputInquiry');
    const selectInquiry = document.querySelector('#selectInquiry');
    
    selectInquiry?.addEventListener('click', () => {
        inputInquiry.value ? selectInquiry.classList.remove('--has-error') : null;
    })
}

export const consultationBtnHandler = () => {
    const form = document.querySelector('#consultasForm');

    if (form) {
        const btn = form.querySelector('.btn-small');
        btn?.addEventListener('click', () => {
            const errorElements = form.querySelectorAll('.--has-error');
            if (errorElements.length == 0) {
                btn.disabled = true;
            }
        });
    }
}
