import axios from "axios";
import ValidationMessages from "./validation-messages";
import { vanillaFade, getCookie, isElementVisible } from "../modules/tools";

export default class EgoForm {
    constructor({
        element,
        type,
        submitType,
        showMessages,
        onBeforeSubmit,
        onSuccess,
        onError,
        fieldGroups,
        serializerIgnoreList,
        resetOnSuccess,
        debug,
    }) {
        this.form = element;
        this.type = type || "contact";
        this.submitType = submitType || "fetch";
        this.csrftoken = getCookie("csrftoken");
        this.showMessages = showMessages || false;
        this.actionUrl = this.form.getAttribute("action");
        this.fetchMethod = this.form.getAttribute("method") || "POST";
        this.submitBtn = this.form.querySelector('button[type="submit"]');
        this.isValid = true;
        this.validationMessages = ValidationMessages;
        this.uploadProgressBar =
            this.form.querySelector(".ego-form__progress-bar") || null;
        this.onBeforeSubmit = onBeforeSubmit || null;
        this.onSuccess = onSuccess || null;
        this.onError = onError || null;
        this.successMessage =
            this.form.querySelector(".ego-form__message.--success") || null;
        this.errorMessage =
            this.form.querySelector(".ego-form__message.--error") || null;
        this.errorMessageDescription = this.errorMessage
            ? this.errorMessage.querySelector(".description")
            : null;
        this.fieldGroups = fieldGroups || null;
        this.hasFile = false;
        this.serializerIgnoreList = serializerIgnoreList || [];
        this.resetOnSuccess = resetOnSuccess || true;
        this.currentStep = this.form.querySelector(".ego-form__step")
            ? parseInt(
                  this.form.querySelector(".ego-form__step.--active").dataset
                      .step
              )
            : 0;
        this.currentStepOptional = false;
        this.stepChanging = false;
        this.debug = debug || false;

        this.declareHandlers();
        if (this.debug) this.showLog("initialized!");
    }

    validateForm() {
        this.isValid = true;
        this.form
            .querySelectorAll(".ego-form__field.--required")
            .forEach((field) => this.validateField(field));
        return this.isValid;
    }

    submit() {
        if (this.debug) this.showLog(`submitting using ${this.submitType}!`);

        this.submittingForm(true);

        // Validate each required field
        this.validateForm();

        if (!this.isValid) {
            this.submittingForm(false);
            if (this.debug) this.showLog(`there are invalid fields.`);
        } else {
            if (this.debug) {
                this.showLog(`the form was submitted!`);
                this.submittingForm(false);

                if (this.showMessages) {
                    vanillaFade({
                        element: this.successMessage,
                        enter: true,
                        time: 200,
                        displayType: "flex",
                    });
                }
            } else {
                if (this.submitType == "fetch") {
                    axios({
                        method: this.fetchMethod,
                        url: this.actionUrl,
                        headers: {
                            Accept: "application/json",
                            "X-CSRFToken": this.csrftoken,
                        },
                        data: new FormData(this.form),
                        onUploadProgress: (p) => {
                            if (this.hasFile && this.uploadProgressBar) {
                                const per = Math.round(
                                    (p.loaded * 100) / p.total
                                );
                                this.uploadProgressBar.style.width = per + "%";
                            }
                        },
                    })
                        .then((resp) => {
                            if (resp.status === 200 || resp.status === 201) {
                                if (this.resetOnSuccess) this.reset();
                                if (this.showMessages && this.successMessage) {
                                    vanillaFade({
                                        element: this.successMessage,
                                        enter: true,
                                        time: 200,
                                        displayType: "flex",
                                    });
                                }
                                if (typeof this.onSuccess == "function")
                                    this.onSuccess(resp);
                            } else {
                                this.displayError(resp);
                                if (typeof this.onError == "function")
                                    this.onError(resp);
                            }
                        })
                        .catch((err) => {
                            console.log("ERROR", err);
                            this.displayError(err);
                            if (typeof this.onError == "function")
                                this.onError(err);
                        })
                        .finally(() => {
                            this.submittingForm(false);
                            if (this.uploadProgressBar)
                                this.uploadProgressBar.style.width = "0%";
                        });
                } else {
                    if (
                        this.onBeforeSubmit &&
                        typeof this.onBeforeSubmit == "function"
                    )
                        this.onBeforeSubmit();
                    this.form.submit();
                }
            }
        }
    }

    submittingForm(sending) {
        let body = document.getElementsByTagName("body").item(0);
        if (sending) {
            this.submitBtn.classList.add("--loading");
            body.classList.add("--block");
        } else {
            this.submitBtn.classList.remove("--loading");
            body.classList.remove("--block");
        }
    }

    validateField(group) {
        const type = group.dataset.type,
            errorElement = group.querySelector(".ego-form__field__error"),
            field =
                type === "radio"
                    ? group.querySelector(".ego-form__control:checked")
                    : group.querySelector(
                          ".ego-form__control, .hidden-control"
                      ),
            fieldName = field ? field.getAttribute("name") : "";

        // Empty validation
        if (field) {
            if (type === "single-checkbox" && !field.checked) {
                this.isValid = false;
                field.setAttribute("aria-invalid", "true");
                group.classList.add("--has-error");
                if (errorElement)
                    errorElement.innerText = this.validationMessages[fieldName]
                        ? this.validationMessages[fieldName].empty
                        : this.validationMessages.default.empty;
            } else {
                if (field.value == "") {
                    this.isValid = false;
                    field.setAttribute("aria-invalid", "true");
                    group.classList.add("--has-error");
                    if (errorElement)
                        errorElement.innerText = this.validationMessages[
                            fieldName
                        ]
                            ? this.validationMessages[fieldName].empty
                            : this.validationMessages.default.empty;
                } else if (group.dataset.max || group.dataset.min) {
                    if (
                        parseInt(this.filterNumber(field.value)) >
                        parseInt(group.dataset.max)
                    ) {
                        this.isValid = false;
                        field.setAttribute("aria-invalid", "true");
                        group.classList.add("--has-error");
                        if (errorElement)
                            errorElement.innerText =
                                "Máximo " +
                                this.filterMoneyAmount(group.dataset.max);
                    } else if (
                        parseInt(this.filterNumber(field.value)) <
                        parseInt(group.dataset.min)
                    ) {
                        this.isValid = false;
                        field.setAttribute("aria-invalid", "true");
                        group.classList.add("--has-error");
                        if (errorElement)
                            errorElement.innerText =
                                "Mínimo " +
                                this.filterMoneyAmount(group.dataset.min);
                    }
                } else {
                    // Custom validations
                    switch (type) {
                        case "email":
                            if (!this.emailValidation(field.value)) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.email.invalid;
                            }
                            break;

                        case "password": {
                            if (!this.passwordValidation(field.value)) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.password.invalid;
                            }
                            break;
                        }

                        case "password_repeat": {
                            const comparedTo =
                                document.getElementById("password1");
                            if (comparedTo && field.value != comparedTo.value) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.password_repeat.unequal;
                            }
                            break;
                        }

                        case "cuil":
                        case "cuit":
                            if (!this.cuitCuilValidation(field.value)) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.cuil.invalid;
                            }
                            break;

                        case "money":
                            const currency = field.dataset.currency
                                ? field.dataset.currency
                                : "$";
                            if (field.value == "" || field.value == currency) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.default.empty;
                            }
                            break;

                        case "rut":
                            if (!this.isRutValid(field.value)) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.rut.invalid;
                            }
                            break;

                        case "license-plate":
                            if (!this.isLicensePlateValid(field.value)) {
                                this.isValid = false;
                                field.setAttribute("aria-invalid", "true");
                                group.classList.add("--has-error");
                                if (errorElement)
                                    errorElement.innerText =
                                        this.validationMessages.license_plate.invalid;
                            }
                            break;

                        default:
                            break;
                    }
                }
            }
        }

        return this.isValid;
    }

    async displayError(error) {
        let errorMsg = error instanceof Response ? await error.json() : error,
            msg = "";
        for (const field in errorMsg) {
            if (Object.hasOwnProperty.call(errorMsg, field)) {
                msg = msg + errorMsg[field][0] + " ";
            }
        }

        if (this.showMessages) {
            if (this.errorMessageDescription)
                this.errorMessageDescription.innerText = msg;

            try {
                vanillaFade({
                    element: this.errorMessage,
                    enter: true,
                    displayType: "flex",
                });
            } catch (e) {
                throw new Error("Element 'errorMessage' not found.");
            }
            return true;
        }

        return false;
    }

    collectData() {
        const formData = new FormData(this.form);
        const sendData = {};

        for (const pair of formData) {
            if (!this.serializerIgnoreList.includes(pair[0])) {
                if (pair[1] instanceof File && !pair[1].size && !pair[1].name) {
                    continue;
                } else {
                    sendData[pair[0]] = pair[1];
                }
            }
        }

        if (this.fieldGroups) {
            for (const groupName in this.fieldGroups) {
                if (Object.hasOwnProperty.call(this.fieldGroups, groupName)) {
                    let group = [{}];
                    for (const field of this.fieldGroups[groupName]) {
                        group[0][field] = formData.get(field);
                        delete sendData[field];
                    }
                    sendData[groupName] = group;
                }
            }
        }
        return sendData;
    }

    reset() {
        this.form.reset();

        this.form
            .querySelectorAll(".ego-form__field:not(.--radio):not(.--select)")
            .forEach((field) => this.resetField(field));

        // Radio
        this.form
            .querySelectorAll(".ego-form__field.--radio")
            .forEach((radio) => {
                this.resetRadioField(radio);
            });

        // Selects
        this.form
            .querySelectorAll(".ego-form__field.--select")
            .forEach((select) => {
                this.resetSelectField(select);
            });
    }

    resetField(field) {
        field.classList.remove("--filled", "--has-error");
        field.setAttribute("aria-invalid", "false");
        // field.querySelector('input').value = '';
    }

    resetRadioField(field) {
        field.classList.remove("--filled", "--has-error");
        field.setAttribute("aria-invalid", "false");

        const checkedRadio = field.querySelector('input[type="radio"]:checked');
        if (checkedRadio) checkedRadio.checked = false;

        if (field.dataset.selected) {
            if (field.dataset.selected === "empty") {
                field.querySelector('input[value=""]').checked = true;
            } else {
                field.querySelector(
                    `input[value="${field.dataset.selected}"]`
                ).checked = true;
            }
        }
    }

    resetSelectField(select) {
        select.classList.remove("--has-error");
        const valuePlaceholder = select.querySelector(".value-placeholder"),
            control = select.querySelector(".hidden-control"),
            defaultSelected = select.dataset.selected;
        if (defaultSelected) {
            if (select.querySelector(".option.--selected"))
                select
                    .querySelector(".option.--selected")
                    .classList.remove("--selected");
            const defaultOption = select.querySelector(
                `.option[data-value="${defaultSelected}"]`
            );
            defaultOption.classList.add("--selected");
            control.value = defaultSelected;
            valuePlaceholder.innerHTML = defaultOption.innerHTML;
        } else {
            valuePlaceholder.innerHTML = "";
            control.value = "";
            select.classList.remove("--filled");
        }
        control.setAttribute("aria-invalid", "false");
    }

    fullReset() {
        this.reset();
        if (this.successMessage && isElementVisible(this.successMessage))
            vanillaFade({ element: this.successMessage, enter: false });
        if (this.errorMessage && isElementVisible(this.errorMessage))
            vanillaFade({ element: this.errorMessage, enter: false });
        if (this.currentStep) this.changeStep(1);
    }

    changeStep(step) {
        if (!this.stepChanging) {
            const current = this.currentStepOptional
                    ? this.currentStep + "b"
                    : this.currentStep,
                currentElement = this.form.querySelector(
                    '[data-step="' + current + '"]'
                ),
                requiredFields = currentElement.querySelectorAll(
                    ".ego-form__field.--required"
                ),
                nextStepNumber =
                    step === "next"
                        ? this.currentStep + 1
                        : step === "prev" && !this.currentStepOptional
                        ? this.currentStep - 1
                        : step === "optional"
                        ? this.currentStep + "b"
                        : this.currentStep,
                nextElement = this.form.querySelector(
                    '[data-step="' + nextStepNumber + '"]'
                );

            if (
                this.currentStep !== nextStepNumber ||
                this.currentStepOptional
            ) {
                this.stepChanging = true;
                this.isValid = true;

                setTimeout(() => {
                    if (
                        requiredFields &&
                        (step === "next" || step === "optional")
                    )
                        requiredFields.forEach((field) =>
                            this.validateField(field)
                        );

                    if (currentElement && nextElement && this.isValid) {
                        vanillaFade({
                            element: currentElement,
                            enter: false,
                            time: 200,
                            displayType: "flex",
                            callback: () => {
                                currentElement.classList.remove("--active");
                                vanillaFade({
                                    element: nextElement,
                                    enter: true,
                                    time: 200,
                                    displayType: "flex",
                                    callback: () => {
                                        nextElement.classList.add("--active");
                                        this.stepChanging = false;
                                        this.currentStepOptional =
                                            step === "optional";
                                        this.currentStep =
                                            parseInt(nextStepNumber);
                                    },
                                });
                            },
                        });
                    } else this.stepChanging = false;
                }, 50);
            }
        }
    }

    nextStep() {
        this.changeStep("next");
    }

    optionalStep() {
        this.changeStep("optional");
    }

    prevStep() {
        this.changeStep("prev");
    }

    emailValidation(email) {
        const expression =
            /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(email.toLowerCase());
    }

    passwordValidation(pass) {
        const expression = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
        return expression.test(pass);
    }

    cuitCuilValidation(num) {
        const multipliers = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        let digits = num.toString().split("");

        const validator = parseInt(digits.pop());

        digits.slice(-1);
        digits = digits.map((digit) => parseInt(digit));

        if (multipliers.length !== digits.length) return false;

        let reduction = digits.reduce((carry, digit, index) => {
            return (carry += digit * multipliers[index]);
        }, 0);

        const division = (reduction / 11).toFixed(2);
        const decimals = division.split(".")[1].split("");
        const decimal =
            decimals[1] > 0 ? parseInt(decimals[0]) + 1 : parseInt(decimals[0]);
        const result = 11 - decimal;

        return validator === result;
    }

    isControlFilled(control) {
        let parent = control.parentElement;
        if (control.value !== "") {
            parent.classList.add("--filled");
        } else {
            parent.classList.remove("--filled");
        }
    }

    clearControlError(control) {
        control.setAttribute("aria-invalid", "false");
        const field =
                control.getAttribute("type") === "checkbox"
                    ? control.parentElement.parentElement
                    : control.parentElement,
            errorElement = field.querySelector(".ego-form__field__error");
        if (errorElement) errorElement.innerText = "";
        field.classList.remove("--has-error");
    }

    filterNumber(value) {
        let reg = /[^0-9]/g;
        return value.replace(reg, "");
    }

    filterNumberWithThousands(value) {
        const number = this.filterNumber(value);
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    filterNumberMin(input, limit) {
        let number = parseInt(input);
        if (number < limit) return limit.toString();
        return input.toString();
    }

    filterNumberMax(input, limit) {
        let number = parseInt(input);
        if (number > limit) return limit.toString();
        return input.toString();
    }

    filterMoneyAmount(num, currency = "$") {
        if (!num || num == currency) return "";
        let result = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        if (!result) return "";
        return `${currency}${result}`;
    }

    // maskLicensePlate(value) {
    //     if (!value) return '';
    //     return value.replace(/[^\w]/g, '')
    //                 .toUpperCase()
    //                 .replace(/\B(?=(.{2})+(?!.))/g, '·');
    // }

    isLicensePlateValid(license) {
        if (!license) return false;
        // const cleaned = license.replace(/[^\w]/g, '');
        // return cleaned.length === 6;
        const licensePlateRegex = /^(?:[A-Za-z]{4}\d{2}|\d{2}[A-Za-z]{4})$/;
        return licensePlateRegex.test(license);
    }

    maskRut(string) {
        if (!string) return "";
        if (string.length === 1) return string;

        const cleaned = string.replace(/[^\w]/g, "");
        const num = cleaned
            .slice(0, -1)
            .replace(/[^\d]/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        const tail = cleaned.slice(-1).replace(/[^a-zA-Z0-9]/g);
        return num ? `${num}-${tail}` : tail;
    }

    isRutValid(rut) {
        if (!rut) return false;

        let sum = 0;
        const rut_clean = String(rut)
            .toLowerCase()
            .match(/[\dk]+/g)
            .join("");
        const rut_body = rut_clean.slice(0, -1);
        const rut_vd = rut_clean.slice(-1);

        const multipliers = [2, 3, 4, 5, 6, 7];

        if (rut_body.length < 7) return false;

        for (let i = 0; i < rut_body.length; i++) {
            const num = rut_body[rut_body.length - 1 - i];
            const by =
                i < multipliers.length
                    ? multipliers[i]
                    : multipliers[i % multipliers.length];
            const subtotal = num * by;
            sum += num * by;
        }

        const vd = 11 - (sum % 11);
        if (vd === 10 && rut_vd === "k") return true;
        if (vd === 11 && rut_vd === "0") return true;
        if (String(vd) === rut_vd) return true;

        return false;
    }

    filterPhoneNumber(value) {
        let reg = /[a-zA-Z*'";:\\/?!@#$%^&=_`~]/g;
        return value.replace(reg, "");
    }

    togglePasswordVisibility(btn) {
        const input = btn.parentElement.querySelector(".ego-form__control");
        if (input) {
            const type =
                input.getAttribute("type") === "password" ? "text" : "password";
            input.setAttribute("type", type);
            btn.classList.toggle("--hide");
        }
    }

    declareHandlers() {
        const self = this;
        if (this.submitBtn) {
            this.submitBtn.addEventListener("click", function (e) {
                e.preventDefault();
                self.submit();
            });
        }

        this.form
            .querySelectorAll(".ego-form__next-step")
            .forEach((element) => {
                element.addEventListener("click", self.nextStep.bind(self));
            });

        this.form
            .querySelectorAll(".ego-form__optional-step")
            .forEach((element) => {
                element.addEventListener("click", self.optionalStep.bind(self));
            });

        this.form
            .querySelectorAll(".ego-form__prev-step")
            .forEach((element) => {
                element.addEventListener("click", self.prevStep.bind(self));
            });

        this.form.querySelectorAll(".ego-form__control").forEach((element) => {
            this.isControlFilled(element);

            element.addEventListener("keyup", () => {
                this.isControlFilled(element);
            });
            element.addEventListener("change", () => {
                this.isControlFilled(element);
            });
        });

        this.form
            .querySelectorAll(".ego-form__field__textarea")
            .forEach((element) => {
                const counter = element.parentElement.querySelector(
                    ".ego-form__field__length-counter > i"
                );
                const max = element.getAttribute("maxlength");
                element.addEventListener("keyup", () => {
                    if (counter) {
                        counter.innerText = max - element.value.length;
                    }
                });

                element.addEventListener("paste", () => {
                    if (counter) {
                        counter.innerText = max - element.value.length;
                    }
                });
            });

        this.form.querySelectorAll(".ego-form__control").forEach((element) => {
            element.addEventListener("focus", () => {
                this.clearControlError(element);
            });
        });

        this.form
            .querySelectorAll('.ego-form__field[data-type="file"]')
            .forEach((field) => {
                const input = field.querySelector('input[type="file"]'),
                    isInTextarea = field.classList.contains(
                        "ego-form__field__attachment"
                    ),
                    fileNameElement = field.querySelector(
                        ".attachment-file-name"
                    ),
                    fileSizeElement = field.querySelector(
                        ".attachment-file-size"
                    ),
                    errorElement = field.querySelector(
                        ".ego-form__field__error"
                    ),
                    removeBtn = field.querySelector(".attachment-remove"),
                    placeholder = field.dataset.placeholder,
                    maxFileSize = field.dataset.maxSize, //MB
                    minFileSizeErrorMessage =
                        "<i>The selected file is empty.</i>",
                    maxFileSizeErrorMessage = `<i><strong>The file is too large</strong>, ${maxFileSize} MB max.</i>`;

                input.addEventListener("change", (e) => {
                    e.stopPropagation();
                    const fileName = e.target.value.split("\\").pop(),
                        fullFileSize = input.files[0].size,
                        fileSize = input.files.length
                            ? (fullFileSize / 1048576).toFixed(1)
                            : 0; //MB
                    if (fileSize > maxFileSize || !fullFileSize) {
                        this.hasFile = false;
                        input.value = "";
                        field.classList.remove("--has-file");
                        fileNameElement.innerText = placeholder;
                        input.setAttribute("aria-invalid", "true");
                        field.classList.add("--has-error");

                        if (isInTextarea) {
                            fileSizeElement.innerHTML = !fullFileSize
                                ? minFileSizeErrorMessage
                                : maxFileSizeErrorMessage;
                        } else {
                            errorElement.innerHTML = !fullFileSize
                                ? minFileSizeErrorMessage
                                : maxFileSizeErrorMessage;
                        }
                    } else if (fileName) {
                        this.hasFile = true;
                        field.classList.add("--has-file");
                        fileSizeElement.innerHTML = `&#10004 ${fileSize}MB`;
                        fileNameElement.innerText = fileName;
                    } else {
                        this.hasFile = false;
                        field.classList.remove("--has-file");
                        fileSizeElement.innerHTML = "";
                        fileNameElement.innerText = placeholder;
                    }
                });

                removeBtn.addEventListener("click", () => {
                    input.value = "";
                    field.classList.remove("--has-file");
                    fileSizeElement.innerHTML = "";
                    fileNameElement.innerText = placeholder;
                });
            });

        // Filter number input
        this.form
            .querySelectorAll(".ego-form__field.--number input")
            .forEach((element) => {
                element.value = this.filterNumber(element.value);

                element.addEventListener("input", () => {
                    element.value = this.filterNumber(element.value);
                });
                element.addEventListener("paste", () => {
                    element.value = this.filterNumber(element.value);
                });
            });

        // Filter number with thousands input
        this.form
            .querySelectorAll(".ego-form__field.--number-with-thousands input")
            .forEach((element) => {
                element.value = this.filterNumberWithThousands(element.value);

                element.addEventListener("input", () => {
                    element.value = this.filterNumberWithThousands(
                        element.value
                    );
                });
                element.addEventListener("paste", () => {
                    element.value = this.filterNumberWithThousands(
                        element.value
                    );
                });
            });

        // Filter money input
        this.form
            .querySelectorAll(".ego-form__field.--money-amount input")
            .forEach((element) => {
                const currency = element.dataset.currency
                    ? element.dataset.currency
                    : "$";
                let result = this.filterNumber(element.value);
                result = this.filterMoneyAmount(result, currency);
                element.value = result;

                element.addEventListener("input", () => {
                    let result = this.filterNumber(element.value);
                    result = this.filterMoneyAmount(result, currency);
                    element.value = result;
                });
                element.addEventListener("paste", () => {
                    let result = this.filterNumber(element.value);
                    result = this.filterMoneyAmount(result, currency);
                    element.value = result;
                });
            });

        // LICENSE PLATE
        // this.form.querySelectorAll('.ego-form__field.--license-plate input')
        //     .forEach(element => {
        //         element.value = this.maskLicensePlate(element.value);

        //         element.addEventListener('input', () => {
        //             element.value = this.maskLicensePlate(element.value);
        //         });
        //         element.addEventListener('paste', () => {
        //             element.value = this.maskLicensePlate(element.value);
        //         });
        //     });

        // RUT
        this.form
            .querySelectorAll(".ego-form__field.--rut input")
            .forEach((element) => {
                element.value = this.maskRut(element.value);

                element.addEventListener("input", () => {
                    element.value = this.maskRut(element.value);
                });
                element.addEventListener("paste", () => {
                    element.value = this.maskRut(element.value);
                });
            });

        // Filter phone input
        this.form
            .querySelectorAll(".ego-form__field.--phone input")
            .forEach((element) => {
                element.value = this.filterPhoneNumber(element.value);

                element.addEventListener("input", () => {
                    element.value = this.filterPhoneNumber(element.value);
                });
                element.addEventListener("paste", () => {
                    element.value = this.filterPhoneNumber(element.value);
                });
            });

        this.form
            .querySelectorAll(".ego-form__message__close")
            .forEach((btn) => {
                btn.addEventListener("click", () => {
                    vanillaFade({ element: btn.parentNode, enter: false });
                });
            });

        this.form
            .querySelectorAll(".ego-form__field__toggle-visibility")
            .forEach((element) => {
                element.addEventListener("click", () => {
                    this.togglePasswordVisibility(element);
                });
            });

        this.form
            .querySelectorAll(".ego-form__field.--select")
            .forEach((select) => {
                const selectedOption =
                    select.querySelector(".option.--selected");
                if (selectedOption) {
                    select.querySelector(".value-placeholder").innerHTML =
                        selectedOption.innerHTML;
                }
            });
    }

    showLog(msg) {
        console.log("::EgoForm:: " + msg);
    }
}
