export function accordionToggle() {
    const acc = document.getElementsByClassName("accordion");
    
    if(acc){
        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                
                const panel = this.nextElementSibling;
                const plus = this.querySelector('.toggle-plus')
                const minus = this.querySelector('.toggle-minus')
        
                if (panel.style.display === "block") {
                    panel.style.display = "none";
                    plus.style.display = "block";
                    minus.style.display = "none";
                } else {
                    panel.style.display = "block";
                    plus.style.display = "none";
                    minus.style.display = "block";
                }
            });
        }
    }
}