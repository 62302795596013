import EgoForm from "../components/ego-form";
// import EgoForm from "@egodesign/forms";
import { getCookie } from "./tools";
import axios from "axios";
import { filterNonDigits } from "../modules/tools";

// Requests default headers
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-CSRFToken"] = getCookie("csrftoken");

export const initForms = () => {
    const formElements = {
        cotizar: document.getElementById("cotizarForm"),
        consultas: document.getElementById("consultasForm"),
        manejo: document.getElementById("manejoForm"),
        // flotas: document.getElementById("flotasForm"),
        reservas: document.getElementById("reservasForm"),
    };

    if (formElements.cotizar) {
        const cotizarForm = new EgoForm({
            element: formElements.cotizar,
            submitType: "fetch",
            showMessages: false,
            onSuccess: () => {
                window.location.href = formElements.cotizar.dataset.successUrl;
                console.log(formElements.cotizar.dataset.successUrl);
            },
            onError: () => {
                window.location.href = formElements.cotizar.dataset.errorUrl;
                console.log(formElements.cotizar.dataset.errorUrl);
            },
        });
    }

    if (formElements.consultas) {
        const consultasForm = new EgoForm({
            element: formElements.consultas,
            submitType: "fetch",
            showMessages: false,
            onSuccess: () => {
                window.location.href =
                    formElements.consultas.dataset.successUrl;
            },
            onError: () => {
                window.location.href = formElements.consultas.dataset.errorUrl;
            },
        });
    }

    if (formElements.manejo) {
        const manejoForm = new EgoForm({
            element: formElements.manejo,
            submitType: "fetch",
            showMessages: false,
            onSuccess: () => {
                window.location.href = formElements.manejo.dataset.successUrl;
                console.log(formElements.manejo.dataset.successUrl);
            },
            onError: () => {
                window.location.href = formElements.manejo.dataset.errorUrl;
                console.log(formElements.manejo.dataset.errorUrl);
            },
        });
    }

    if (formElements.reservas) {
        const reservasForm = new EgoForm({
            element: formElements.reservas,
            submitType: "fetch",
            showMessages: false,
            onSuccess: () => {
                window.location.href = formElements.reservas.dataset.successUrl;
                console.log(formElements.reservas.dataset.successUrl);
            },
            onError: () => {
                window.location.href = formElements.reservas.dataset.errorUrl;
                console.log(formElements.reservas.dataset.errorUrl);
            },
        });
    }
};
