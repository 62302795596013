export const seriesListDT = () => {
    const ulSerie = document.querySelector('#serieListDT');
    const ulVersion = document.querySelector('#versionListDT');
    const serieSelected = document.querySelector('#serieSelectedDT');
    const versionSelected = document.querySelector('#versionSelectedDT');
    const inputSerie = document.querySelector('#inputSerieDT');
    const inputVersion = document.querySelector('#inputVersionDT');
    const selectSerie = document.querySelector('#selectSerieDT');
    const selectVersion = document.querySelector('#selectVersionDT');

    if (ulSerie) {
        fetch('/api/vehicles/series/')
            .then(response => response.json())
            .then(series => {
                series.forEach(serie => {
                    // Crear un li por cada serie
                    const liSerie = document.createElement('li');
                    liSerie.textContent = serie.name;
                    liSerie.className = 'option';
    
                    liSerie.addEventListener('click', () => {
                        fetch(`/api/vehicles/series/?serie_id=${serie.id}`)
                            .then(response => response.json())
                            .then(serie => serie[0].versions)
                            .then(versions => {
                                ulVersion.innerHTML = ''; // Limpiar versiones anteriores
    
                                versions.forEach(version => {
                                    // Crear un li por cada versión y lo agrego al ul
                                    const liVersion = document.createElement('li');
                                    liVersion.textContent = version.name;
                                    liVersion.className = 'option';
                                    ulVersion?.appendChild(liVersion);
    
                                    liVersion.addEventListener('click', () => {
                                        // Para mostrar la versión seleccionada en el select
                                        versionSelected.textContent = version.name;
                                        inputVersion.value = version.id;
                                        inputVersion.value ? selectVersion.classList.remove('--has-error') : null;
    
                                        // Obtener el elemento se agregarán los elementos
                                        const parentElement = document.querySelector('#cotizarForm');
    
                                        // Crear el div para mostrar la versión seleccionada
                                        const versionDiv = document.createElement('div');
                                        versionDiv.classList.add('version_box');
                                        versionDiv.id = 'version_box';
    
                                        // Crear p para mostrar la versión seleccionada
                                        const versionText = document.createElement('p');
                                        versionText.classList.add('version_box_version');
                                        versionText.id = 'version_box_version';
                                        versionText.textContent = version.name;
    
                                        // Crear la imagen de la versión seleccionada
                                        const versionImg = document.createElement('img');
                                        versionImg.classList.add('version_box_img');
                                        versionImg.id = 'version_box_img';
                                        versionImg.alt = version.name;
                                        if(version.image){
                                            versionImg.src = version.image.url;
                                        }
    
                                        // Agregar los elementos al div
                                        versionDiv?.appendChild(versionText);
                                        versionDiv?.appendChild(versionImg);
    
                                        // Agregar el div al form
                                        parentElement?.appendChild(versionDiv);
                                        
                                        // Mover el div versión antes del botón
                                        const button = document.querySelector('#cotizarFormBtn');
                                        parentElement?.insertBefore(versionDiv, button);
                                    });
                                })
                            });
    
                            // Para mostrar la serie seleccionada en el select
                            serieSelected.textContent = serie.name;
                            inputSerie.value = serie.id;
                            inputSerie.value ? selectSerie.classList.remove('--has-error') : null;
                    });
                    
                    // Agregar el li al ul de series
                    ulSerie?.appendChild(liSerie);
                });
            })
            .catch(error => console.error(error));
    }
}

export const branchesList = () => {
    const branchesList = document.getElementById('branchesList');
    const branchSelected = document.getElementById('branchSelected');
    const inputBranch = document.getElementById('inputBranch');
    const selectBranch = document.getElementById('selectBranch');

    if(branchesList) {
        fetch('/api/dealers/branches/')
            .then(response => response.json())
            .then(data => {
                data.forEach(item => {
                    // Crear un li por cada branch y lo agrego al ul
                    const li = document.createElement('li');
                    li.textContent = item.name;
                    li.className = 'option';
                    branchesList?.appendChild(li);
    
                    li.addEventListener('click', () => {
                        // Para mostrar la versión seleccionada en el select
                        branchSelected.textContent = item.name;
                        inputBranch.value = item.id;
                        inputBranch.value ? selectBranch.classList.remove('--has-error') : null;
                    })
                });
            })
            .catch(error => {
                console.error('Error al obtener los elementos de la lista:', error);
            });
    }
}

export const driveBtnHandler = () => {
    const form = document.querySelector('#manejoForm');

    if (form) {
        const btn = form.querySelector('.btn-small');
        btn?.addEventListener('click', () => {
            const errorElements = form.querySelectorAll('.--has-error');
            if (errorElements.length == 0) {
                btn.disabled = true;
            }
        });
    }
}
