export const getHeight = () => {
    if (window.innerWidth >= 600) {
        let container = document.querySelectorAll("#big-photo-and-description-somos-lexus");
        
        container.forEach((item) => {
            let element = item.querySelectorAll("#big-photo-and-description-somos-lexus__child");
            
            element.forEach((el) => {
                el.style.height = item.clientHeight + "px";
            })
        })
    }
}