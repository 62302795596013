export const handleEgoSelects = () => {
    const selects = document.querySelectorAll(".--select, .--select-text");
    if (selects) {
        window.addEventListener("click", (e) => {
            document
                .querySelectorAll(".--select.--open")
                .forEach((select) => select.classList.remove("--open"));
            document
                .querySelectorAll(".--select-text.--open")
                .forEach((select) => select.classList.remove("--open"));
        });

        selects.forEach((select) => {
            select.addEventListener("click", (e) => {
                e.stopPropagation();
                select.classList.toggle("--open");
            });

            select
                .querySelectorAll(".ego-form__field__select-dropdown .option")
                .forEach((option) => {
                    option.addEventListener("click", (e) => {
                        if (option.dataset.value) {
                            // console.log("select clicked");
                            const control =
                                select.querySelector(".hidden-control");
                            select.classList.add("--filled");
                            select.querySelector(
                                ".value-placeholder"
                            ).innerText = option.innerText;
                            control.value =
                                option.dataset.value === "all"
                                    ? ""
                                    : option.dataset.value;
                            control.dispatchEvent(new Event("value-change"));

                            select
                                .querySelectorAll(".option.--selected")
                                .forEach((opt) =>
                                    opt.classList.remove("--selected")
                                );
                            option.classList.add("--selected");
                        }
                    });
                });
        });
    }
};
