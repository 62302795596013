export const citiesList = () => {
    const ulRegion = document.querySelector("#regionList");
    const ulComuna = document.querySelector("#comunaList");
    const regionSelected = document.querySelector("#regionSelected");
    const comunaSelected = document.querySelector("#comunaSelected");
    const inputRegion = document.querySelector("#inputRegion");
    const inputComuna = document.querySelector("#inputComuna");
    const selectRegion = document.querySelector("#selectRegion");
    const selectComuna = document.querySelector("#selectComuna");

    if (ulRegion) {
        fetch("/api/core/regions/")
            .then((response) => response.json())
            .then((regions) => {
                regions.forEach((region) => {
                    // Crear un li por cada serie
                    // console.log(serie);
                    const liRegion = document.createElement("li");
                    liRegion.className = "option";
                    liRegion.textContent = region.name;
                    liRegion.dataset.value = region.id;

                    liRegion.addEventListener("click", (e) => {
                        if (liRegion.dataset.value) {
                            // console.log("select clicked");
                            const control =
                                selectRegion.querySelector(".hidden-control");
                            selectRegion.classList.add("--filled");
                            selectRegion.classList.remove("--has-error");
                            regionSelected.textContent = liRegion.innerText;
                            control.value =
                                liRegion.dataset.value === "all"
                                    ? ""
                                    : liRegion.dataset.value;
                            control.dispatchEvent(new Event("value-change"));

                            selectRegion
                                .querySelectorAll(".option.--selected")
                                .forEach((opt) =>
                                    opt.classList.remove("--selected")
                                );
                            liRegion.classList.add("--selected");
                        }
                    });

                    liRegion.addEventListener("click", () => {
                        // regionSelected.textContent = serie.name;

                        // inputRegion.value = serie.id;

                        selectComuna.classList.remove("--disabled");

                        // Limpiar comunas anteriores
                        ulComuna.innerHTML = "";

                        fetch(`/api/core/cities/?region=${region.id}`)
                            .then((response) => response.json())
                            .then((comuna) => {
                                comuna.forEach((comuna) => {
                                    const liComuna =
                                        document.createElement("li");
                                    liComuna.textContent = comuna.name;
                                    liComuna.className = "option";

                                    liComuna.addEventListener("click", () => {
                                        comunaSelected.textContent =
                                            comuna.name;
                                        inputComuna.value = comuna.id;
                                        inputComuna.value
                                            ? selectComuna.classList.remove(
                                                  "--has-error"
                                              )
                                            : null;
                                    });

                                    ulComuna.appendChild(liComuna);
                                });
                            });
                    });

                    // Agregar el li al ul de series
                    ulRegion.appendChild(liRegion);
                });
            })
            .catch((error) => console.error(error));
    }
};
