import Swiper from "swiper/bundle";
import 'swiper/css';
import { vanillaFade } from "../modules/tools";

export function bannerSwiper() {
    if (document.querySelector(".banner-swiper")) {
        const swiper = new Swiper(".banner-swiper__sliders__slider", {
            spaceBetween: 0,
            centeredSlides: true,
            loop: true,
            speed: 800,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: false,
            },
            breakpoints: {
              1440: {
                spaceBetween: 0,
                slidesPerView: 1,
                centeredSlides: true,                
              }
            },
            on: {
                activeIndexChange: function (swiper) {
                    const slides = swiper.params.loop ? swiper.slides.length - 2 : swiper.slides.length;
                    if (swiper.previousIndex === 0 || swiper.previousIndex > slides) return false;

                    const nextIndex = swiper.activeIndex === 0 ? slides : swiper.activeIndex > slides ? 1 : swiper.activeIndex;
                    const current = document.querySelector(`.slides-content[data-slide="${swiper.previousIndex}"]`);
                    const next = document.querySelector(`.slides-content[data-slide="${nextIndex}"]`);
                    if (current && next) {
                        vanillaFade({
                            element: current,
                            enter: false,
                            time: 400, 
                            displayType: 'block', 
                            callback: () => {
                                current.classList.remove('.--show');
                                vanillaFade({
                                    element: next,
                                    enter: true,
                                    time: 400, 
                                    displayType: 'block', 
                                    callback: () => {
                                        next.classList.add('.--show');
                                    }
                                });
                            }
                        });
                    }
                },
            },
        });
    }
}