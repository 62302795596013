import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import 'air-datepicker/air-datepicker.css';

export const formDate = () => {
    const datePicker = new AirDatepicker('#appointment_date', {
        locale: localeEn,
        dateFormat: 'yyyy-MM-dd',
        onRenderCell({date, cellType}) {
            if (cellType === 'day') {
                if (date.getDay() === 0) {
                    return {
                        disabled: true,
                        classes: 'disabled-class',
                        attrs: {
                            title: 'Cell is disabled'
                        }
                    }
                }
            }
        },
    });

};
