export const headerBehavior = () => {
    window.addEventListener('scroll', function(){
        let header = document.querySelector('.header')
        if(window.scrollY !== 0 && header){
            header.classList.add('--fixed');
        } else if(window.scrollY == 0 && header){
            header.classList.remove('--fixed');
        }
    })
}

export const mobileMenuFunctionality = () => {
    let active = false
    const animationTime = 300;
    
    const toggleButton = document.querySelector('#toggler');
    const secondaryNav = document.querySelector('#secondary-nav');
    const menuContent = document.querySelector('.header__menu-button');
    const htmlElement = document.querySelector('html');
    const bodyElement = document.querySelector('body');

    if(toggleButton && secondaryNav && menuContent){
        toggleButton.addEventListener('click', () => {
            if(!active){
                toggleButton.classList.add('active');
                secondaryNav.classList.add('open');
                menuContent.classList.add('open');
                htmlElement.classList.add('no-scroll');
                bodyElement.classList.add('no-scroll');
            } else {
                toggleButton.classList.remove('active');
                secondaryNav.classList.remove('open');
                menuContent.classList.remove('open');
                setTimeout(() => {
                    htmlElement.classList.remove('no-scroll');
                    bodyElement.classList.remove('no-scroll');
                }, animationTime);
            }
            active = !active;
        });
    }
}

export const languages = () => {
    const btn = document.querySelector('.language-btn');
    const options = document.querySelector('.language-options');

    if(btn && options){
        btn.addEventListener('click', () => {
            options.classList.toggle('active');
        });
    }
}